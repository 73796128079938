/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 97번째 로그에서는 인프런 강좌, 아이맥, 당근마켓 채용 라이브 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "subicura의 인프런 강좌"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/course/%EB%8F%84%EC%BB%A4-%EC%9E%85%EB%AC%B8"
  }, "초보를 위한 도커 안내서")), "\n"), "\n", React.createElement(_components.h3, null, "아이맥 27인치 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/imac/"
  }, "iMac 27인치")), "\n"), "\n", React.createElement(_components.h3, null, "당근마켓 채용 라이브"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/1185"
  }, "[방구석 LIVE] 당근마켓 TS, Java, Go, Rails / SRE, ML 채용 오픈 세션")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
